@import 'node_modules/normalize.css/normalize';

html,
body,
#root {
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
